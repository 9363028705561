import React from "react"
import { VideoWrapped } from "../../components/reactPlayerTest"
import TheaterVideo from "../../components/Theater/TheaterVideo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"

function Sizzle({ playing, children, language, youtube }) {
  return (
    <div className="home-sizzle-wrapper">
      <VideoWrapped playing={playing} id="test-player-1" />
      {/* {youtube && youtube !== "?" && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          overVideo
          asWrapper
          wrapperClassName="static-heading__theater-btn"
          language={language}
        >
          <FontAwesomeIcon icon={faPlayCircle} />
        </TheaterVideo>
      )} */}
      {children}
    </div>
  )
}

export default Sizzle
