import React from "react"
import "../../components/animations/scroll.scss"
import PropTypes from "prop-types"
import TheaterVideo from "@components/Theater/TheaterVideo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

function StaticHeading(props) {
  const {
    post: { svgHeading, svgSubheading },
    youtube,
    heading,
    language
  } = props

  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading"
    >
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset", textAlign: "left" }}
      >
        <div className="curtain-heading-container">
          <h1
            className="curtain-heading h3-style"
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h1>
        </div>
        <MarkdownViewer
          className="above-text__subheader"
          markdown={svgSubheading}
        />

        {youtube && (
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
            controls
            playing
            onlyButton
            sizzleButton
            buttonClass="sizzle-button-under contained"
            compId="sizzleTheaterButton"
            language={language}
          />
        )}
      </div>
    </div>
  )
}

StaticHeading.propTypes = {
  post: PropTypes.object.isRequired,
  language: PropTypes.string
}

export default StaticHeading
